import {useEffect, useState} from "react";

import {getSinglePost} from "../components/contentful";

export default function useSinglePost(slug) {

    const [post, setPost] = useState(null);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        // Move the promise inside the useEffect
        setLoading(true); // Ensure loading state is reset in case of slug change
        getSinglePost(slug).then(result => {
            if (result.length > 0) {
                setPost(result[0].fields); // Ensure you check the result's length to avoid errors
                setLoading(false);
            } else {
                // Handle the case where the post doesn't exist or an error occurs
                console.error("Post not found or an error occurred.");
                setLoading(false);
                setPost(null); // Reset post state or set to a 'not found' state
            }
        });
    }, [slug]); // Depend on slug so the effect runs when it changes

    return [post, isLoading];
}
