import React from 'react';
import './Footer.css'; // Ensure you create a corresponding CSS file

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-section">
                <h3>CONTACT US</h3>
                <p>Koninginnelaan 69<br/>
                    3905 GG Veenendaal<br/>
                    the Netherlands</p>
                <p>+31 (0)20 62 750 62</p>
                <p>For inquiries:<br/>
                    info@duchenne.org</p>
                <p>Data Protection Officer:<br/>
                    privacy@duchennedatafoundation.org</p>
            </div>
            <div className="footer-section">
                <h3>USEFUL LINKS</h3>
                <a href="/feedback">Feedback</a>
                <a href="/Contact/Contact">Contact</a>
                <a href="/news">News</a>
                <a href="/terms">Terms of Service</a>
                <a href="/privacy">Privacy Policy</a>
                <a href="/security">Security Policy</a>
            </div>
            <div className="footer-section">
                <h3>SOCIAL</h3>
                <a href="https://www.facebook.com">Facebook</a>
                <a href="https://www.twitter.com">Twitter</a>
                <a href="mailto:info@duchenne.org">Email</a>
                {/* Include newsletter subscription form if needed */}
            </div>
        </footer>
    );
}

export default Footer;
