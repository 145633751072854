import React, {useEffect, useState} from 'react';
import {MapContainer, TileLayer, Marker, Popup} from 'react-leaflet';
// import { useAuthState } from 'react-firebase-hooks/auth';
// import { getAuth } from 'firebase/auth';
import Slider from "rc-slider";
import L from 'leaflet';
import 'rc-slider/assets/index.css';
import './Map.css'

// Correct the default marker icon path issue
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const MapComponent = () => {
    const [locations, setLocations] = useState([]);
//   const auth = getAuth();
//   const [user, loading] = useAuthState(auth);
    const [clinicalTrials, setClinicalTrials] = useState([]);
    const [filter, setFilter] = useState({
        ambulatory: false,
        corticosteroidUse: false,
        ageRange: [0, 30],
    });

    const handleSliderChange = (value) => {
        setFilter(prev => ({...prev, ageRange: value}));
    };

    const handleCheckboxChange = (event) => {
        const {name, checked} = event.target;
        setFilter(prev => ({...prev, [name]: checked}));
    };

    useEffect(() => {
        fetch('https://repository.duchennedatafoundation.org/api/3/action/datastore_search?id=DMD%20and%20BMD%20Clinical%20Trials&limit=500&fields=NCTID%2CTitle%2CSex%2CLast%20Update%20Submit%20Date%2CMentionsCorticosteroidUse%2CMinimum%20Age%20(Years)%2CMaximum%20Age%20(Years)%2CMentionsAmbulatoryUse%2COverall%20Status&filters={%22Overall%20Status%22%3A[%22AVAILABLE%22%2C%22RECRUITING%22%2C%22ENROLLING_BY_INVITATION%22]}')
            .then(response => response.json())
            .then(data => setClinicalTrials(data.result.records))
            .catch(error => console.error('Error fetching clinical trials data:', error));
    }, []);

    useEffect(() => {
        fetch('https://repository.duchennedatafoundation.org/api/3/action/datastore_search?id=DMD%20and%20BMD%20GeoLocation&limit=5000')
            .then(response => response.json())
            .then(data => setLocations(data.result.records))
            .catch(error => console.error('Error fetching data:', error));
    }, []);

//   if (loading) return <div>Loading...</div>;

    const filteredLocations = locations.filter(location => {
        const trial = clinicalTrials.find(trial => trial.NCTID === location.nctid);
        // If no matching trial is found, exclude the location
        if (!trial) return false;

        // Start with a flag that assumes inclusion
        let includeLocation = true;

        // Check corticosteroid use filter
        if (filter.corticosteroidUse && !trial.MentionsCorticosteroidUse) {
            includeLocation = false;
        }

        // Check ambulatory filter
        if (filter.ambulatory && !trial.MentionsAmbulatoryUse) {
            includeLocation = false;
        }

        // Check the age filter
        const trialMinAge = parseInt(trial["Minimum Age (Years)"], 10);
        const trialMaxAge = parseInt(trial["Maximum Age (Years)"], 10) || 100;
        const [minAge, maxAge] = filter.ageRange;
        if (trialMinAge > minAge || trialMaxAge < maxAge) {
            includeLocation = false;
        }

        // Return the final inclusion flag
        return includeLocation;
    });

    return (<div className="map-and-filter-container">
        <MapContainer center={[0, 0]} zoom={2}
                      style={{height: '800px', width: '80%', flexGrow: 1, flexBasis: '80%'}}>
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            {filteredLocations.map((location, index) => {
                // Find the matching trial by nctid
                const trial = clinicalTrials.find(trial => trial.NCTID === location.nctid);

                return (
                    <Marker key={index} position={[location.Latitude, location.Longitude]}>
                        <Popup>
                            <div className="popup-content">
                                <p><strong>NCTID:</strong> {location.nctid}</p>
                                <p><strong>Title:</strong> {trial?.Title || 'N/A'}</p>
                                <p><strong>Status:</strong> {trial?.['Overall Status'] || 'N/A'}</p>
                                <p><strong>Sex:</strong> {trial?.Sex || 'N/A'}</p>
                                <p><strong>Last Update:</strong> {trial?.['Last Update Submit Date'] || 'N/A'}</p>
                                <p><strong>Minimum Age:</strong> {trial?.['Minimum Age (Years)'] || 'N/A'}</p>
                                <p><strong>Maximum Age:</strong> {trial?.['Maximum Age (Years)'] || 'N/A'}</p>
                                <p><strong>Find more:</strong> <a
                                    href={`https://clinicaltrials.gov/study/${location.nctid}`} target="_blank"
                                    rel="noopener noreferrer">{location.nctid}</a></p>
                            </div>
                        </Popup>
                    </Marker>
                );
            })}
        </MapContainer>
        <div className="filter-sidebar">
            <div className="filter-title">Clinical Trials</div>
            <form>
                <div className="checkbox-label">
                    <div className="checkbox-wrapper-22">
                        <label className="switch" htmlFor="ambulatory">
                            <input
                                type="checkbox"
                                id="ambulatory"
                                name="ambulatory"
                                checked={filter.ambulatory}
                                onChange={handleCheckboxChange}
                            />
                            <div className="slider round"></div>
                        </label>
                        <label htmlFor="ambulatory">Able to join if Ambulatory</label>
                    </div>
                </div>
                <div className="checkbox-label">
                    <div className="checkbox-wrapper-22">
                        <label className="switch" htmlFor="corticosteroidUse">
                            <input
                                type="checkbox"
                                id="corticosteroidUse"
                                name="corticosteroidUse"
                                checked={filter.corticosteroidUse}
                                onChange={handleCheckboxChange}
                            />
                            <div className="slider round"></div>
                        </label>
                        <label htmlFor="corticosteroidUse">Able to join with use of Corticosteroids</label>
                    </div>
                </div>
                <div className="slider-container">
                    <label>Age Range: {filter.ageRange[0]} - {filter.ageRange[1]}</label>
                    <Slider
                        range
                        min={0}
                        max={30}
                        defaultValue={filter.ageRange}
                        onChange={handleSliderChange}
                    />
                </div>
            </form>
        </div>
    </div>);
};

export default MapComponent;
