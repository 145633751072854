import React from 'react';
import {Link, useParams} from "react-router-dom";
import {readableDate} from "../../helpers";
import useSinglePost from "../../../hooks/useSinglePost";
import "./SinglePost.css"
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";

const SinglePost = () => {
    const {id} = useParams();
    const [post, isLoading] = useSinglePost(id);

    const renderPosts = () => {
        if (isLoading) return <p>Loading...</p>;
        const descriptionComponent = post.description ? documentToReactComponents(post.description) : null;
        return (
            <React.Fragment>
                <div className="post__intro">
                    <h2 className="post__intro__title">{post.title}</h2>
                    <small className="post__intro__date">{readableDate(post.published)}</small>
                    <p className="post__intro__desc">{post.shortDescription}</p>

                    <img
                        className="post__intro__img"
                        src={post.thumbnailImage.fields.file.url}
                        alt={post.title}
                    />
                </div>

                <div className="post__body">
                    {descriptionComponent}
                </div>
            </React.Fragment>
        )
    }

    return (
        <div className="post">
            <Link className="post__back" to="/blog">
                {"< Back"}
            </Link>

            {renderPosts()}
        </div>
    );
};

export default SinglePost;