import React from 'react';
import './Contact.css'; // Ensure you create a corresponding CSS file

const Contact = () => {
    return (
        <>
            <main className="contact-container">
                <section className="contact-info">
                    <h2>Contact</h2>
                    <div className="info-group">
                        <h3>Address</h3>
                        <p>Koninginnelaan 69<br />
                        3905 GG Veenendaal<br />
                        the Netherlands</p>
                    </div>
                    <div className="info-group">
                        <h3>Contact</h3>
                        <p>Tel.: +31 (0)20 6275062<br />
                        E-mail: info@duchenne.org</p>
                    </div>
                    <div className="info-group">
                        <h3>Important numbers</h3>
                        <p>IBAN: NL 75 ABNA 06205 499 2064<br />
                        BIC: ABNANL2A<br />
                        Number Chamber of Commerce: 64601188</p>
                    </div>
                </section>
                <section className="contact-form">
                    <form>
                        <input type="text" placeholder="Your full name" name="name" />
                        <input type="email" placeholder="Your email" name="email" />
                        <textarea placeholder="Your message" name="message"></textarea>
                        <button type="submit">Submit</button>
                    </form>
                </section>
            </main>
        </>
    );
}

export default Contact;
