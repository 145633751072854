import {Link} from "react-router-dom";
import {readableDate} from "../../helpers";
import usePosts from "../../../hooks/usePosts";
import "./Posts.css";

const Posts = () => {
    const [posts, isLoading] = usePosts();

    const renderPosts = () => {
        if (isLoading) return <p>Loading...</p>;
        return posts.map(post => (
            <Link
                className="posts__post"
                key={post.fields.slug}
                to={`/blog/${post.fields.slug}`}
            >
                <div className="posts__post__img__container">
                    <img
                        className="posts__post__img__container__img"
                        src={post.fields.thumbnailImage.fields.file.url}
                        alt={post.fields.title}
                    />
                </div>
                <small>{readableDate(post.fields.published)}</small>
                <h3>{post.fields.title}</h3>

                <p>{post.fields.shortDescription}</p>
            </Link>
        ));
    }

    return (
        <div className="posts__container">
            <h2>News</h2>

            <div className="posts">{renderPosts()}</div>
        </div>
    );
};

export default Posts;