import React from 'react';
import WhatWeDo from '../AboutUs/WhatWeDo';
import HeroSection from './HeroSection';
import MapComponent from '../Map/Map';

const Home = () => {
    return (
        <div>
            <WhatWeDo/>
            <MapComponent/>
            <HeroSection/>
        </div>
    );
}

export default Home;
