// src/contexts/AuthContext.js
import React, {createContext, useContext, useEffect, useState} from 'react';
import {auth} from '../firebase-config'; // Adjust the import path as necessary
import {onAuthStateChanged} from 'firebase/auth';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({children}) => {
    const [currentUser, setCurrentUser] = useState(null);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, user => {
            setCurrentUser(user);
        });

        // Cleanup subscription on unmount
        return unsubscribe;
    }, []);

    return (
        <AuthContext.Provider value={{currentUser}}>
            {children}
        </AuthContext.Provider>
    );
};
