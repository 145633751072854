import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {auth} from '../../firebase-config'; // Ensure you're importing auth from where you've initialized it
import {sendEmailVerification, signInWithEmailAndPassword, signOut} from 'firebase/auth';
import './SignIn.css';

const SignIn = () => {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });
    // Add a state to manage the loading state
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const userCredential = await signInWithEmailAndPassword(auth, formData.email, formData.password);
            const user = userCredential.user;

            if (!user.emailVerified) {
                // Send a verification email if the user's email is not verified.
                await sendEmailVerification(user);
                await signOut(auth); // Optionally, sign out the user after sending the verification email.
                alert("A verification email has been sent. Please verify your email before signing in.");
            } else {
                // Proceed with your app's logged-in experience.
                navigate('/');
            }
        } catch (error) {
            console.error('Error signing in:', error);
            // Handle error (e.g., user not found, wrong password, etc.)
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="form-container">
            <form onSubmit={handleSubmit} className="sign-in-form">
                <div className="form-group">
                    <label htmlFor="email">Email Address</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input
                        type="password"
                        id="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        required
                    />
                </div>
                {/* Conditionally render the button or loading animation */}
                {isLoading ? (
                    <div className="loading-animation">Loading...</div> // Placeholder for your loading animation
                ) : (
                    <button type="submit" className="sign-in-button">Sign In</button>
                )}
            </form>
        </div>
    );
};

export default SignIn;
