const client = require("contentful").createClient({
    space: "7mgvcgav7ltl",
    accessToken: "9uCpn2I1JyROQuv2QahiEIv8GlUlzMwk6IGU5tz9xlw"
});

const getBlogPosts = () => client.getEntries().then(response => response.items);

const getSinglePost = slug =>
    client
        .getEntries({
            "fields.slug": slug,
            content_type: "newsPost"
        })
        .then(response => response.items);

export { getBlogPosts, getSinglePost };
