import React from 'react';
import ReactDOM from 'react-dom';
import './index.css'; // Make sure you have this file for basic styling
import App from './App';

ReactDOM.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// reportWebVitals(); // This is optional
