import React from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from 'react-router-dom';
import Home from './components/Home/Home';
import Contact from './components/Contact/Contact';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Posts from "./components/News/Posts/Posts";
import SinglePost from "./components/News/SinglePost/SinglePost";
import Registration from './components/Authentication/Registration';
import PatientRegistrationDetails from './components/Authentication/PatientRegistrationDetails';
import SignIn from './components/Authentication/SignIn';
import {AuthProvider} from './context/AuthContext';
import 'leaflet/dist/leaflet.css';
import './App.css';

function App() {
    return (
        <Router>
            <AuthProvider>
                <div className="App">
                    <Header/>
                    <Routes>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/blog" element={<Posts/>}/>
                        <Route path="/blog/:id" element={<SinglePost/>}/>
                        <Route path="/contact" element={<Contact/>}/>
                        <Route path="/register" element={<Registration/>}/>
                        <Route path="/register-patient" element={<PatientRegistrationDetails/>}/>
                        <Route path="/login" element={<SignIn/>}/>
                        {/* You can add more Route components here for other pages */}
                    </Routes>
                    <Footer/>
                </div>
            </AuthProvider>
        </Router>
    );
}

export default App;