// WhatWeDo.js

import React from 'react';
import './WhatWeDo.css'; // Ensure you create this CSS file

const WhatWeDo = () => {
    return (
        <section className="what-we-do">
            <h2>What we do</h2>
            <p>Helping families to take better decisions for Duchenne healthcare by collecting information and putting
                it in one place.</p>
            <div className="wwd-container">
                <div className="wwd-item">
                    <img src="icons/network.png" alt="Connect"/>
                    <h3>Connect</h3>
                    <p>Engage and connect with the local and global Duchenne community to advance development in DMD
                        healthcare.</p>
                </div>
                <div className="wwd-item">
                    <img src="icons/understand.png" alt="Understand"/>
                    <h3>Understand</h3>
                    <p>Develop a deeper understanding of the Duchenne & Becker muscular dystrophy field.</p>
                </div>
                <div className="wwd-item">
                    <img src="icons/contribute.png" alt="Contribute"/>
                    <h3>Contribute</h3>
                    <p>Contribute to the collection of validated information to promote Duchenne healthcare.</p>
                </div>
                <div className="wwd-item">
                    <img src="icons/accessibility.png" alt="Access"/>
                    <h3>Access</h3>
                    <p>Find practical information about support, care, and information, help identify and contribute to
                        research projects.</p>
                </div>
                <div className="wwd-item">
                    <img src="icons/credible.png" alt="Credible"/>
                    <h3>Credible</h3>
                    <p>In Duchenne Map, all information is verified and guaranteed up-to-date.</p>
                </div>
                <div className="wwd-item">
                    <img src="icons/community.png" alt="Community"/>
                    <h3>Community</h3>
                    <p>Be visible in the global Duchenne community, share knowledge and connect with peers and
                        stakeholders.</p>
                </div>
            </div>
        </section>
    );
};

export default WhatWeDo;
