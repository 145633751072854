// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth} from 'firebase/auth';
import { getFirestore } from "firebase/firestore";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDa_0WBcg5XGp8dw-xAbbjQm2oo7hGPFsc",
  authDomain: "duchenne-map.firebaseapp.com",
  projectId: "duchenne-map",
  storageBucket: "duchenne-map.appspot.com",
  messagingSenderId: "172824186509",
  appId: "1:172824186509:web:33f0e9790c2df9e3681cc4",
  measurementId: "G-F6MWEZ7TZD"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
// const analytics = getAnalytics(app);

export {app, auth, db};